import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";

// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import Pricing from '../components/sections/Pricing';
import Team from '../components/sections/Team';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import GenericSection from '../components/sections/GenericSection';
import Roadmap from '../components/sections/Roadmap';
import Cta from '../components/sections/Cta';
// import some required elements
import Image from '../components/elements/Image';
import Input from '../components/elements/Input';
import ButtonGroup from '../components/elements/ButtonGroup';
import Button from '../components/elements/Button';
import Modal from '../components/elements/Modal';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';

// 8-byte hex string corresponding to the Celestia namespace
const NAMESPACE = "4545454545454545"

class Secondary extends React.Component {

  constructor() {
    super()
    this.state = {
      blocks: null,
      start: 0,
    }
  }

  async componentDidMount() {
    // Get last 20 blocks
    let res = await fetch(`https://get-namespaced-data.constellationlabs.workers.dev/${NAMESPACE}`)
    let json = await res.json()
    let e = json[0]
    let skip = Math.max(e.metadata[0].total - 20, 0)
    res = await fetch(`https://get-namespaced-data.constellationlabs.workers.dev/${NAMESPACE}?skip=${skip}&limit=20`)
    json = await res.json()
    e = json[0]
    let blocks = e.data.sort((e1, e2) => Number(e2.height) - Number(e1.height))
    console.log(blocks.length)
    this.setState({ blocks, total: e.metadata[0].total })
  }

  render() {  
    return (
      <React.Fragment>
        <GenericSection topDivider>
          <div className="container-xs">
            <h2 className="mt-0">Lyra Data Explorer</h2>
            <p>
              A lightweight explorer for Lyra's namespaced data on the Celestia testnet.
            </p>
            <p>
              Thanks to LZRS/<a href="https://celestiascan.vercel.app/">Celestiascan</a> for providing a "PayForData" API.
            </p>
            <h4>Recent Blobs</h4>
            {!this.state.blocks && <ClipLoader color="white"/>}
            {this.state.blocks && <>
              <p>
                Most recent {this.state.blocks && this.state.blocks.length} blobs:
              </p>
              {this.state.blocks && this.state.blocks.map((block, i) => {
                return (<div style={{backgroundColor: "white", margin: 4, padding: 20}}>
                  <span style={{fontSize: "0.7rem", color: "#050505"}}>Celestia Block #{block.height}</span> {/*| Lyra Block #{this.state.total - i}*/}
                  <div style={{overflow: "auto", height: "8rem", wordWrap: "break-word"}}>{block.data}</div>
                </div>)
              })}
            </> }
          </div>
        </GenericSection>
      </React.Fragment>
    );
  }
}

// inline style
const formStyle = {
  maxWidth: '420px',
  margin: '0 auto'
}

const modalFormStyle = {
  maxWidth: '320px',
  margin: '0 auto'
}

export default Secondary;