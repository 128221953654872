let ethereum = window.ethereum

const addNetwork = async () => {
    console.log("ADDING NETWORK")
    try {
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x236d' }],
        });
    } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0x236d',
                  chainName: 'Lyra Testnet',
                  rpcUrls: ['https://lyra-rpc.constellationlabs.xyz:8545'],
                  nativeCurrency: {
                      name: "CST",
                      symbol: "CST",
                      decimals: 18,
                  }
                },
              ],
            });
          } catch (addError) {
            // handle "add" error
          }
        }
        // handle other "switch" errors
    }
}


export {
  addNetwork
}